// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-alien-haze-tsx": () => import("./../../../src/pages/alien-haze.tsx" /* webpackChunkName: "component---src-pages-alien-haze-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-cbd-blueten-tsx": () => import("./../../../src/pages/cbd-blueten.tsx" /* webpackChunkName: "component---src-pages-cbd-blueten-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-indian-charas-tsx": () => import("./../../../src/pages/indian-charas.tsx" /* webpackChunkName: "component---src-pages-indian-charas-tsx" */),
  "component---src-pages-order-success-tsx": () => import("./../../../src/pages/order-success.tsx" /* webpackChunkName: "component---src-pages-order-success-tsx" */),
  "component---src-pages-sour-tangie-tsx": () => import("./../../../src/pages/sour-tangie.tsx" /* webpackChunkName: "component---src-pages-sour-tangie-tsx" */),
  "component---src-pages-spaghetti-cheese-tsx": () => import("./../../../src/pages/spaghetti-cheese.tsx" /* webpackChunkName: "component---src-pages-spaghetti-cheese-tsx" */),
  "component---src-pages-strawberry-haze-tsx": () => import("./../../../src/pages/strawberry-haze.tsx" /* webpackChunkName: "component---src-pages-strawberry-haze-tsx" */),
  "component---src-pages-tropical-haze-tsx": () => import("./../../../src/pages/tropical-haze.tsx" /* webpackChunkName: "component---src-pages-tropical-haze-tsx" */),
  "component---src-pages-versand-tsx": () => import("./../../../src/pages/versand.tsx" /* webpackChunkName: "component---src-pages-versand-tsx" */),
  "component---src-pages-zahlungsarten-tsx": () => import("./../../../src/pages/zahlungsarten.tsx" /* webpackChunkName: "component---src-pages-zahlungsarten-tsx" */),
  "component---src-pages-zkittlez-tsx": () => import("./../../../src/pages/zkittlez.tsx" /* webpackChunkName: "component---src-pages-zkittlez-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

