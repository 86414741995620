module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"b22fdf84-cae8-45b3-8a3c-d630cce5004e","enableDuringDevelop":true,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"o484v"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172628231-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"optimizeId":"OPT-TRHZ3ZM","defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"auto"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140,"quality":90,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://lilahase.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
